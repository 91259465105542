import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    meta: { requiresAuth: true },
    children: [
      // Dashboard
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard/Dashboard.vue"),
      },
      // Certification
      {
        path: "/certifications",
        name: "certifications",
        component: () => import("@/views/Certification/Certification.vue"),
      },
      {
        path: "/certifications/edit/:id?",
        name: "certifications-edit",
        component: () => import("@/views/Certification/CertificationForm.vue"),
        props: true,
      },
      {
        path: "/certifications/new",
        name: "certifications-new",
        component: () => import("@/views/Certification/CertificationForm.vue"),
      },
      {
        path: "/certifications/certificationHistory/:id?",
        name: "certificationHistory",
        component: () => import("@/views/Certification/CertificationHistory.vue"),
        props: true,
      },
      {
        path: "/certifications/AddDocuments/:id?",
        name: "CertificationAddDocuments",
        component: () => import("@/views/Certification/CertificationAddDocuments.vue"),
        props: true,
      },
      {
        path: "/certifications/AmendDocuments/:id?",
        name: "CertificationAmendDocuments",
        component: () => import("@/views/Certification/CertificationAmendDocuments.vue"),
        props: true,
      },
      // Sub Certification
      {
        path: "/sub_certifications",
        name: "sub-certifications",
        component: () => import("@/views/Certification/SubCertification.vue"),
      },
      {
        path: "/sub_certifications/edit/:id?",
        name: "sub-certifications-edit",
        component: () =>
          import("@/views/Certification/SubCertificationForm.vue"),
        props: true,
      },
      {
        path: "/sub_certifications/new",
        name: "sub-certifications-new",
        component: () =>
          import("@/views/Certification/SubCertificationForm.vue"),
        props: true,
      },
      // Operations
      {
        path: "/review",
        name: "projects-review",
        component: () =>
          import("@/views/Operations/ProjectsReview.vue"),
      },
      // Audit Report
      {
        path: "/audit-reports",
        name: "audit-reports",
        component: () => import("@/views/AuditReport/AuditReports.vue"),
      },
      // Audit Report - English
      {
        path: "/audit-reports/new/en",
        name: "audit-reports-new-en",
        component: () => import("@/views/AuditReport/AuditReportEnglish.vue"),
      },
      {
        path: "/audit-reports/edit/en/:audit_report_id",
        name: "audit-reports-edit-en",
        props: true,
        component: () => import("@/views/AuditReport/AuditReportEnglish.vue"),
      },
      // Audit Report - Arabic
      {
        path: "/audit-reports/new/ar",
        name: "audit-reports-new-ar",
        component: () => import("@/views/AuditReport/AuditReportArabic.vue"),
      },
      {
        path: "/audit-reports/edit/ar/:audit_report_id",
        name: "audit-reports-edit-ar",
        props: true,
        component: () => import("@/views/AuditReport/AuditReportArabic.vue"),
      },
      // Qualified Report
      {
        path: "/qualified-reports",
        name: "qualified-reports",
        component: () => import("@/views/QualifiedReport/QualifiedReports.vue"),
      },
      // Qualified Report - English
      {
        path: "/qualified-reports/new/en",
        name: "qualified-reports-new-en",
        props: true,
        component: () =>
          import("@/views/QualifiedReport/QualifiedReportEnglish.vue"),
      },
      {
        path: "/qualified-report-en/:qualified_report_id?",
        name: "qualified-report-en",
        props: true,
        component: () =>
          import("@/views/QualifiedReport/QualifiedReportEnglish.vue"),
      },
      // Qualified Report - Arabic
      {
        path: "/qualified-reports/new/ar",
        name: "qualified-reports-new-ar",
        props: true,
        component: () =>
          import("@/views/QualifiedReport/QualifiedReportArabic.vue"),
      },
      {
        path: "/qualified-report-ar/:qualified_report_id?",
        name: "qualified-report-ar",
        props: true,
        component: () =>
          import("@/views/QualifiedReport/QualifiedReportArabic.vue"),
      },

      
      

      //SSB Report
      {
        path: "/ssb-reports/edit/en/:ssb_report_id",
        name: "ssb-reports-edit-en",
        props: true,
        component: () => import("@/views/SSBReport/SSBReportEnglish.vue"),
      },
      {
        path: "/ssb-reports/edit/ar/:ssb_report_id",
        name: "ssb-reports-edit-ar",
        props: true,
        component: () => import("@/views/SSBReport/SSBReportArabic.vue"),
      },

      // Observation
      {
        path: "/observations",
        name: "observations",
        component: () =>
          import("@/views/ManageData/Observations/Observations.vue"),
      },
      {
        path: "/observations/edit/:id?",
        name: "observations-edit",
        component: () =>
          import("@/views/ManageData/Observations/ObservationForm.vue"),
        props: true,
      },
      {
        path: "/observations/new",
        name: "observations-new",
        component: () =>
          import("@/views/ManageData/Observations/ObservationForm.vue"),
      },
      // References
      {
        path: "/references",
        name: "references",
        component: () => import("@/views/ManageData/References/References.vue"),
      },
      {
        path: "/references/edit/:id?",
        name: "references-edit",
        component: () =>
          import("@/views/ManageData/References/ReferenceForm.vue"),
        props: true,
      },
      {
        path: "/references/new",
        name: "references-new",
        component: () =>
          import("@/views/ManageData/References/ReferenceForm.vue"),
        props: true,
      },
      // Shariyah Companies
      {
        path: "/shariyah-companies",
        name: "shariyah-companies",
        component: () =>
          import("@/views/ManageData/ShariyahCompanies/ShariyahCompanies.vue"),
      },
      {
        path: "/shariyah-companies/edit/:id?",
        name: "shariyah-companies-edit",
        component: () =>
          import(
            "@/views/ManageData/ShariyahCompanies/ShariyahCompanyForm.vue"
          ),
        props: true,
      },
      {
        path: "/shariyah-companies/new",
        name: "shariyah-companies-new",
        component: () =>
          import(
            "@/views/ManageData/ShariyahCompanies/ShariyahCompanyForm.vue"
          ),
        props: true,
      },
      // Departments
      {
        path: "/departments",
        name: "departments",
        component: () =>
          import("@/views/ManageData/Departments/Departments.vue"),
      },
      {
        path: "/departments/edit/:id?",
        name: "departments-edit",
        component: () =>
          import("@/views/ManageData/Departments/DepartmentForm.vue"),
        props: true,
      },
      {
        path: "/departments/new",
        name: "departments-new",
        component: () =>
          import("@/views/ManageData/Departments/DepartmentForm.vue"),
      },
      // Scholars
      {
        path: "/scholars",
        name: "scholars",
        component: () => import("@/views/ManageData/Scholars/Scholars.vue"),
      },
      {
        path: "/scholars/edit/:id?",
        name: "scholars-edit",
        component: () => import("@/views/ManageData/Scholars/ScholarForm.vue"),
        props: true,
      },
      {
        path: "/scholars/new",
        name: "scholars-new",
        component: () => import("@/views/ManageData/Scholars/ScholarForm.vue"),
      },
      // Sectors
      {
        path: "/sectors",
        name: "sectors",
        component: () => import("@/views/ManageData/Sectors/Sectors.vue"),
      },
      {
        path: "/sectors/edit/:id?",
        name: "sectors-edit",
        component: () => import("@/views/ManageData/Sectors/SectorForm.vue"),
        props: true,
      },
      {
        path: "/sectors/new",
        name: "sectors-new",
        component: () => import("@/views/ManageData/Sectors/SectorForm.vue"),
      },
      // Guidelines
      {
        path: "/guidelines",
        name: "guidelines",
        component: () => import("@/views/ManageData/Guidelines/Guidelines.vue"),
      },

      {
        path: "/guidelines/edit/:id?",
        name: "guidelines-edit",
        component: () =>
          import("@/views/ManageData/Guidelines/GuidelineForm.vue"),
        props: true,
      },
      {
        path: "/guidelines/new",
        name: "guidelines-new",
        component: () =>
          import("@/views/ManageData/Guidelines/GuidelineForm.vue"),
      },
      // Product Classification
      {
        path: "/product-classifications",
        name: "product-classifications",
        component: () =>
          import(
            "@/views/ManageData/ProductClassifications/ProductClassifications.vue"
          ),
      },

      {
        path: "/product-classifications/edit/:id?",
        name: "product-classifications-edit",
        component: () =>
          import(
            "@/views/ManageData/ProductClassifications/ProductClassificationForm.vue"
          ),
        props: true,
      },
      {
        path: "/product-classifications/new",
        name: "product-classifications-new",
        component: () =>
          import(
            "@/views/ManageData/ProductClassifications/ProductClassificationForm.vue"
          ),
      },
      // Product Types
      {
        path: "/product-types",
        name: "product-types",
        component: () =>
          import("@/views/ManageData/ProductTypes/ProductTypes.vue"),
      },
      {
        path: "/product-types/edit/:id?",
        name: "product-types-edit",
        component: () =>
          import("@/views/ManageData/ProductTypes/ProductTypeForm.vue"),
        props: true,
      },
      {
        path: "/product-types/new",
        name: "product-types-new",
        component: () =>
          import("@/views/ManageData/ProductTypes/ProductTypeForm.vue"),
      },
      // Product Documents
      {
        path: "/product-documents",
        name: "product-documents",
        component: () =>
          import("@/views/ManageData/ProductDocuments/ProductDocuments.vue"),
      },
      {
        path: "/product-documents/edit/:id?",
        name: "product-documents-edit",
        component: () =>
          import("@/views/ManageData/ProductDocuments/ProductDocumentForm.vue"),
        props: true,
      },
      {
        path: "/product-documents/new",
        name: "product-documents-new",
        component: () =>
          import("@/views/ManageData/ProductDocuments/ProductDocumentForm.vue"),
      },
      // Manage Users
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/Users/Users.vue"),
      },
      {
        path: "/users/new",
        name: "users-new",
        component: () => import("@/views/Users/UserForm.vue"),
      },
      {
        path: "/users/edit/:id?",
        name: "users-edit",
        component: () => import("@/views/Users/UserForm.vue"),
        props: true,
      },

      // Business
      // {
      //   path: "/business-affairs",
      //   name: "BusinessAffairs",
      //   component: () => import("@/views/Business/BusinessAffairs.vue"),
      // },

      // {
      //   path: "/client-affairs",
      //   name: "ClientAffairs",
      //   component: () => import("@/views/Business/ClientAffairs.vue"),
      // },


      // Clients
      {
        path: "/clients",
        name: "clients",
        component: () => import("@/views/Business/Clients.vue"),
      },
      {
        path: "/clients/edit/:id",
        name: "clients-edit",
        component: () => import("@/views/Business/ClientForm.vue"),
        props: true,
      },
      {
        path: "/clients/new",
        name: "clients-new",
        component: () => import("@/views/Business/ClientForm.vue"),
      },

      // Reports
      {
        path: "/excel-export",
        name: "ExcelExport",
        component: () => import("@/views/Reports/ExcelExport.vue"),
      },
      {
        path: "/mm-report",
        name: "MMReports",
        component: () => import("@/views/Reports/MMReports.vue"),
      },

      // Manage Employees
      {
        path: "/employees",
        name: "employees",
        component: () => import("@/views/Employees/Employees.vue"),
      },
      {
        path: "/employees/edit/:id?",
        name: "employees-edit",
        component: () => import("@/views/Employees/EmployeeForm.vue"),
        props: true,
      },
      {
        path: "/employees/new",
        name: "employees-new",
        component: () => import("@/views/Employees/EmployeeForm.vue"),
      },

       // Manage Signature Platform
       {
        path: "/sign-dashboard",
        name: "Dashboard",
        component: () => import("@/views/SignaturePlatform/Dashboard/Dashboard.vue"),
      },
       {
        path: "/get-signatures",
        name: "signatures",
        component: () => import("@/views/SignaturePlatform/SaveSignature/SaveSignature.vue"),
      },
      // {
      //   path: "/upload-file",
      //   name: "UploadFile",
      //   component: () => import("@/views/SignaturePlatform/SignDocuments/SelectDocuments.vue"),
      // },
      // {
      //   path: "/send-signatures",
      //   name: "SendSignatures",
      //   component: () => import("@/views/SignaturePlatform/SignDocuments/SendSignatures.vue"),
      // },
      // {
      //   path: "/add-signer",
      //   name: "AddSigner",
      //   component: () => import("@/views/SignaturePlatform/SignDocuments/AddSigners.vue"),
      // },
      // {
      //   path: "/review-send",
      //   name: "ReviewSend",
      //   component: () => import("@/views/SignaturePlatform/SignDocuments/ReviewSend.vue"),
      // },
      {
        path: "/documents",
        name: "Documents",
        component: () => import("@/views/SignaturePlatform/Documents/Documents.vue"),
      },
      {
        path: "/stepper",
        name: "Stepper",
        component: () => import("@/components/stepper/Stepper.vue"),
      },


    ],
  },
  {
    path: "/",
    redirect: "/sign-in",
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/Authentication/SignIn.vue"),
  },
  {
    path: "/collect-response-en/:audit_report_id/:audit_report_name",
    name: "collect-response-en",
    props: true,
    component: () =>
      import("@/views/AuditReport/ResponseCollection/ResponseFormEnglish.vue"),
  },
  {
    path: "/collect-response-ar/:audit_report_id/:audit_report_name",
    name: "collect-response-ar",
    props: true,
    component: () =>
      import("@/views/AuditReport/ResponseCollection/ResponseFormArabic.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/Authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    try {
      const isAuthenticated = await store.dispatch("checkAuth");
      if (isAuthenticated) {
        next();
      } else {
        store.dispatch("logout");
        next({ name: "sign-in" }); // Redirect to login if not authenticated
      }
    } catch (error) {
      store.dispatch("logout");
      next({ name: "sign-in" }); // Redirect to login on error
    }
  } else {
    next(); // Allow navigation if not requiring authentication
  }
});
export default router;
