import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";

config.rawError = true;


interface RootState {
  graphClient: any; // Replace 'any' with the actual type of your graphClient
}

interface Box {
  boxId: string;
  top: number;
  left: number;
  width: number;
  height: number;
}

const store = createStore({
  state: {
    graphClient: null,
    currentStep: 1,
    selectedDocument: null, 
    signers: [
      {
        name: "",
        email: "",
        color: "",
      },
    ],
    fields: [],
    ccEmails: [],
    emailTitle: "",
    emailMessage: "",
    relativePositions: {},
  },  
  
  mutations: {  
    setGraphClient(state, client) {
      console.log("index.ts - Setting graph client in state:", client);
      state.graphClient = client;
    },  
    setCurrentStep(state, step) {
      state.currentStep = step;
    },
    uploadFile(state, file) {
      // if (file) {
      //   state.selectedDocument = file[0];
      // }  
      if (file instanceof File) {
        state.selectedDocument = file;
      } else if (Array.isArray(file) && file.length > 0) {
        state.selectedDocument = file[0];
      } else {
        state.selectedDocument = null;
      }
      
    },  
    addSigner(state) {
      state.signers.push({ name: "", email: "" });
    },
    addSignerInformation(state, {name, email}) {
      if (state.signers.length === 1 && state.signers[0].name ==="" && state.signers[0].email==="") {
        state.signers[0].name = name;
        state.signers[0].email = email;
      } else if (state.signers.length < 1) {
        state.signers.push({ name: "", email: "" });
        state.signers[0].name = name;
        state.signers[0].email = email;
      } else if (state.signers.length >= 1) { //if more than one (non empty) users
        state.signers = []; //first delete all signers
        state.signers.push({ name: "", email: "" });
        state.signers[0].name = name;
        state.signers[0].email = email;
      }
    },
    removeSigner(state, index) {
      state.signers.splice(index, 1);
    },
    goNext(state) {
        state.currentStep += 1;
    },     
    goBack(state) {
        state.currentStep -= 1;
    },      
    addSignatureField(state, field) {
      state.fields.push(field);
    },
    updateSignatureField(state, { index, updatedField }) {
      state.fields[index] = updatedField;
    },
    removeSignatureField(state, index) {
      state.fields.splice(index, 1);
    },
    addCCEmails(state) {
      state.ccEmails.push("");
    },
    removeCCEmails(state, index) {
      state.ccEmails.splice(index, 1);
    },
    updateCCEmails(state, {index, newEmail}) {
      state.ccEmails[index] = newEmail;
    },
    setEmailTitle(state, title) {
      state.emailTitle = title;
    },
    setEmailMessage(state, message) {
      state.emailMessage = message;
    },


    addRelativePosition(state, {email, relativePosition}) {
      if(!state.relativePositions[email]) {
        state.relativePositions[email] = [];
      }
      // console.log("email in addRelativePosition(): ", email);
      // console.log("relativePosition in addRelativePosition(): ", relativePosition);

      state.relativePositions[email].push(relativePosition);

      // console.log("state.relativePositions[email] in addRelativePosition(): ", state.relativePositions[email]);
    },

    updateRelativePosition(state, { email, relativePosition }) {
      const index = state.relativePositions[email]?.findIndex(
        (box: Box) => box.boxId === relativePosition.boxId
      );
      // console.log("email in updateRelativePosition(): ", email);
      // console.log("relativePosition in updateRelativePosition(): ", relativePosition);
      // console.log('index in updateRelativePosition(): ', index);

      if (index !== -1 ) {
        state.relativePositions[email][index] = relativePosition;
        // console.log("state.relativePositions[email] in updateRelativePosition(): ", state.relativePositions[email]);
      }


    },
    deleteRelativePosition(state, {email, index}) {
      if(state.relativePositions[email]) {
        
        // console.log("email in deleteRelativePosition(): ", email);
        // console.log('index in deleteRelativePosition(): ', index);

        state.relativePositions[email].splice(index, 1);
        if(state.relativePositions[email].length === 0) {
          delete state.relativePositions[email]; //to remove key if it is empty
        }
      }
    },
 
    // updateRelativePosition(state, { boxId, canvasId, top, left, width, height }) {
    //   if (!state.relativePositions[boxId]) {
    //     state.relativePositions[boxId] = {};
    //   }
    //   state.relativePositions[boxId][canvasId] = { top, left, width, height };
    // },

  },

  actions: {
    updateGraphClient({ commit }, client) {
      console.log("index.ts - Updating graph client:", client);
      commit('setGraphClient', client);
    },
    setCurrentStep({ commit }, step) {
      commit('setCurrentStep', step)
    },
    uploadFile({ commit }, file) {
      commit('uploadFile', file)
    },
    addSigner({ commit }) {
      commit('addSigner')
    },
    addSignerInformation({ commit }, payload) {
      console.log('payload :>> ', payload);
      commit('addSignerInformation', payload)
    },
    removeSigner({ commit }, index) {
      commit('removeSigner', index)
    },
    goNext({ commit }) {
      commit('goNext')
    },
    goBack({ commit }) {
      commit('goBack')
    },
    addSignatureField({ commit }, payload) {
      commit("addSignatureField", payload);
    },
    updateSignatureField({ commit }, payload) {
      commit("updateSignatureField", payload);
    },
    removeSignatureField({ commit }, index) {
      commit("removeSignatureField", index);
    },
    addCCEmails({ commit }) {
      commit("addCCEmails");
    },
    removeCCEmails({ commit }, index) {
      commit("removeCCEmails", index);
    },
    updateCCEmails( { commit }, payload) {
      commit("updateCCEmails", payload )
    },
    setEmailTitle({ commit }, title) {
      commit("setEmailTitle", title);
    },
    setEmailMessage({ commit }, message) {
      commit("setEmailMessage", message);
    },
    // updateRelativePosition({ commit }, payload) {
    //   commit("updateRelativePosition", payload)
    // }

    addRelativePosition({ commit }, payload) {
      // console.log("payload received in addRelativePosition action: ", payload);
      commit("addRelativePosition", payload);
    },
  
    updateRelativePosition({ commit }, payload) {
      // console.log("payload received in updateRelativePosition action: ", payload);
      commit("updateRelativePosition", payload);
    },
  
    deleteRelativePosition({ commit }, payload) {
      commit("deleteRelativePosition", payload);
    },

  },

  getters: {
    graphClient: (state) => state.graphClient, // Access the graphClient from the state
    // signers: (state) => state.signers,
    // currentStep: (state) => state.currentStep,
  },
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ThemeModeModule,
  },
});

export default store;

// https://devblogs.microsoft.com/azure-sdk/vue-js-user-authentication/