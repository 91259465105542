import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { Client } from "@microsoft/microsoft-graph-client";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { login_backend } from "@/core/services/ApiService";
import { StartLoading, StopLoading } from "@/core/services/AlertService";
const pca = new PublicClientApplication({
  auth: {
    clientId: process.env.VUE_APP_CLIENT_ID as string,
    authority: process.env.VUE_APP_AUTHORITY as string,
    redirectUri: process.env.VUE_APP_REDIRECT_URI as string,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
});

function convertBlobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
}


async function convertImageToBase64(imagePath) {
  const response = await fetch(imagePath);
  const blob = await response.blob();
  return convertBlobToBase64(blob); // Pass Blob to your convertBlobToBase64 function
}

const actions = {
  async initializeAuth({ dispatch }) {
    await pca.initialize();
    await pca.handleRedirectPromise();

    const accounts = pca.getAllAccounts();
    if (accounts.length > 0) {
      const account = accounts[0];
      const result = await pca.acquireTokenSilent({
        account,
        scopes: ["User.Read", "Calendars.read", "Mail.Send"],
      });
      if (result && result.expiresOn) {
        const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
          pca,
          {
            account: account,
            interactionType: InteractionType.Popup,
            scopes: ["User.Read", "Calendars.read", "Mail.Send"],
          }
        );
        const gClient = Client.initWithMiddleware({ authProvider });
        dispatch("updateGraphClient", gClient);
        const expiryTime = result.expiresOn
          ? result.expiresOn.getTime()
          : Date.now() + 3600000; // Set default expiry time to 1 hour if null
        const email = result.account.username;
        const idToken = result.idToken;

        const user = await gClient.api("/me").get();
        let profilePicture;
        try {
          profilePicture = await gClient.api("me/photo/$value").get();
        } catch (error) {
          // console.error("Image Error Found: ", error);
          console.log("No profile picture found.");
          profilePicture = null; // Set profilePicture to null if there's an error fetching it
        }

        let base64data;
        if (profilePicture) {
          console.log("Found profile picture");

          base64data = await convertBlobToBase64(profilePicture);
        } else {
          console.log("No profile picture found, using default image.");
          // Handle default image logic here, e.g., setting a default image
          base64data = await convertImageToBase64('media/avatars/blank.png'); // Path to your default image
        }
        const response = await login_backend(email, idToken);
        if (response.ok) {
          const result = await response.json();
          localStorage.setItem('user', JSON.stringify(result));
          localStorage.setItem(
            "auth",
            JSON.stringify({
              id: result.id,
              isAuthenticated: true,
              tokenExpiry: expiryTime,
              email: email,
              name: user.displayName,
              profilePicture: base64data??"",
              idToken: idToken,
              description: result.description,
              role: result.role,
              sector: result.sector,
            })
          );

          return gClient;
        }
      } else {
        this.logout();
        return null;
      }
    } else {
      return null;
    }
  },
  async login({ dispatch }) {
    await pca.initialize();
    try {
      const result = await pca.acquireTokenPopup({
        scopes: ["User.Read", "Calendars.read", "Mail.Send"],
      });

      if (!result.account) {
        throw new Error("Could not authenticate");
      }

      const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(pca, {
        account: result.account,
        interactionType: InteractionType.Popup,
        scopes: ["User.Read", "Calendars.read", "Mail.Send"],
      });
      const gClient = Client.initWithMiddleware({ authProvider });
      dispatch("updateGraphClient", gClient);
      const expiryTime = result.expiresOn
        ? result.expiresOn.getTime()
        : Date.now() + 3600000; // Set default expiry time to 1 hour if null
      const email = result.account.username;
      const idToken = result.idToken;

      const user = await gClient.api("/me").get();
      let profilePicture;
      try {
        profilePicture = await gClient.api("me/photo/$value").get();
      } catch (error) {
        console.log("No profile picture found.");
        profilePicture = null; // Set profilePicture to null if there's an error fetching it
      }

      let base64data;
      if (profilePicture) {
        console.log("Found profile picture -2");
        base64data = await convertBlobToBase64(profilePicture);
      } else {
        console.log("No profile picture found, using default image.");
        base64data = await convertImageToBase64('media/avatars/blank.png'); // Path to your default image
        // Handle default image logic here, e.g., setting a default image
      }
      StartLoading("Logging In...");
      const response = await login_backend(email, idToken);
      if (response.ok) {
        const result = await response.json();
        localStorage.setItem('user', JSON.stringify(result));
        localStorage.setItem(
          "auth",
          JSON.stringify({
            id: result.id,
            isAuthenticated: true,
            tokenExpiry: expiryTime,
            email: email,
            name: user.displayName,
            profilePicture: base64data ?? "",
            idToken: idToken,
            description: result.description,
            role: result.role,
            sector: result.sector,
          })
        );

        StopLoading();
        return gClient;
      }
      return null;
    } catch (error) {
      console.error("Authentication error:", error);
      throw error;
    }
  },
  logout() {
    localStorage.removeItem("auth");
  },
  checkAuth() {
    const authDataString = localStorage.getItem("auth");
    const authData = authDataString ? JSON.parse(authDataString) : null;
    const isAuthenticated = authData && authData.isAuthenticated;
    const tokenExpiry = authData ? authData.tokenExpiry : null;
    const isTokenExpired = tokenExpiry ? Date.now() > tokenExpiry : true;
    if (isAuthenticated) {
      if (isTokenExpired) {
        Swal.fire({
          title: "Unauthorized!",
          text: "Session Expired. Please login again!",
          icon: "info",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  },
};

export default {
  actions,
};
